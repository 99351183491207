<template>
  <div>
    <Header class="text-sm text-gray-600 text-justify">
      <h3 class="mb-2 text-lg leading-6 font-medium text-gray-900">Resumo dos Termos e condições</h3>
      <p class="mt-1">A VW Soluções é uma empresa de automação industrial e oferece serviços relacionados, principalmente, com projetos eletrônicos e programações.</p>
      <p class="mt-1">Para operar na plataforma, todas as Pessoas Usuárias devem aceitar os Termos e Condições, os anexos e a Declaração de Privacidade.</p>
      <p class="mt-1">Cada Pessoa Usuária é responsável pelos dados pessoais fornecidos no momento do cadastro e se compromete a mantê-los atualizados.</p>
      <p class="mt-1">Além disso, a Pessoa Usuária é a única responsável pelo uso e proteção da sua senha.</p>
      <h3 class="mt-4 mb-2 text-lg leading-6 font-medium text-gray-900">Termos e condições gerais de uso do site</h3>
      <h4 class="my-2 font-medium text-gray-900">1- Termos e condições</h4>
      <p class="mt-1">O termo “Pessoa/s Usuária/s” será definido neste contrato para representar por cada conta individual, sendo por CPF ou CNPJ.</p>
      <p class="mt-1">Estes termos e condições e os anexos que explicam os serviços da VW Soluções (doravante designados os “Termos e Condições”) regulam a relação entre a VW Soluções e as pessoas que usam seus serviços e produtos (doravante designadas as “Pessoas Usuárias”).</p>
      <p class="mt-1">As Pessoas Usuárias aceitam estes Termos e Condições a partir do momento em que se registram no Site.</p>
      <p class="mt-1">As Pessoas Usuárias aceitam estes Termos e Condições a partir do momento em que realiza qualquer compra no Site e/ou por solicitações via e-mail e/ou por solicitações via WhatsApp.</p>
      <p class="mt-1">Quando devermos fazer mudanças importantes em nossos serviços, publicaremos as modificações com 5 dias corridos de antecedência para que as Pessoas Usuárias possam revisá-las. Em nenhum caso afetarão as operações que já tenham finalizado.</p>
      <h4 class="my-2 font-medium text-gray-900">2- Registro e conta</h4>
      <p class="mt-1">Quem quiser usar nossos serviços, deverá preencher o formulário de registro com os dados que lhe forem requeridos. Ao completá-lo, concorda em fazê-lo de forma exata, precisa e verdadeira e manter seus dados sempre atualizados.</p>
      <p class="mt-1">A Pessoa Usuária será a única responsável pela certeza dos dados de registro. Sem prejuízo das informações fornecidas no formulário, poderemos solicitar e/ou consultar informações adicionais para verificar a identidade da Pessoa Usuária.</p>
      <p class="mt-1">A conta é pessoal, única e intransferível, ou seja, em nenhuma circunstância poderá ser vendida ou transferida para outra pessoa. A conta é acessada com a senha pessoal de segurança escolhida e deverá ser mantida sob estrita confidencialidade. Em qualquer caso, a Pessoa Usuária será sempre a única responsável pelas operações realizadas na sua conta. Caso a Pessoa Usuária identifique o uso não autorizado de sua conta, ela deverá fazer imediatamente a mudança de senha.</p>
      <p class="mt-1">Poderemos rejeitar um pedido de registro ou cancelar um registro já aceito, sem que isso gere direito a uma indenização. Não poderão se registrar novamente no site as Pessoas Usuárias que tenham sido desativadas previamente. Também não poderão se registrar aqueles que estejam incluídos ou relacionados com pessoas que apareçam em listas nacionais ou internacionais de sanções. <a href="https://www.portaltransparencia.gov.br/sancoes/lista-consultas" class="text-primary font-medium" target="_blank">Saiba mais</a>.</p>
      <h4 class="my-2 font-medium text-gray-900">3- Privacidade de dados</h4>
      <p class="mt-1">Em nosso site fazemos uso responsável das informações pessoais, protegendo a privacidade das Pessoas Usuárias que nos confiaram seus dados e tomando as medidas necessárias para garantir a segurança. <a href="https://www.mercadolivre.com.br/privacidade/declaracao-privacidade" class="text-primary font-medium" target="_blank">Saiba mais sobre nossa Declaração de privacidade</a>.</p>
      <h4 class="my-2 font-medium text-gray-900">4- Sanções</h4>
      <p class="mt-1">Caso a Pessoa Usuária viole uma lei ou os Termos de Condições e Serviço, poderemos avisar, suspender, restringir ou desativar temporariamente ou definitivamente sua conta, sem prejuízo de outras sanções que se estabeleçam nas regras de uso particulares dos serviços da VW Soluções.</p>
      <h3 class="mt-4 mb-2 text-lg leading-6 font-medium text-gray-900">Termos gerais de serviço</h3>
      <h4 class="my-2 font-medium text-gray-900">1- Responsabilidade</h4>
      <p class="mt-1">A VW Soluções não é responsável por nenhum dano consequente do uso dos equipamentos e produtos comercializados sob nenhuma circunstância incluindo perda de receita, tempo parado, danos ou substituição de equipamentos ou propriedades e qualquer custo de recuperação, reprogramação ou reprodução de dados com o uso de um determinado hardware adquirido.</p>
      <h4 class="my-2 font-medium text-gray-900">2- Orçamento</h4>
      <p class="mt-1">O prazo de validade do orçamento enviado é de 7 dias contatos da data de elaboração do mesmo. Após este período o orçamento perde automaticamente a validade, podendo haver alterações de preço e disponibilidade de estoque.</p>
      <p class="mt-1">O cliente autoriza o envio do orçamento nos canais de comunicações (E-mail) utilizados pela VW Soluções.</p>
      <p class="mt-1">Os orçamentos aprovados deverão ser respondidos via e-mail, mediante ao comprovante de pagamento anexado no mesmo.</p>
      <h4 class="my-2 font-medium text-gray-900">3- Garantia</h4>
      <p class="mt-1">A VW Soluções garante por defeitos de fabricação e componentes pelo prazo de 90 dias a contar da data da emissão da nota fiscal. Se descobrir um defeito nós iremos, sob nossa opção, reparar, trocar ou devolver o valor pago. Nós iremos devolver o seu produto ou outro com as mesmas características via encomenda normal (PAC). Envio via Sedex está disponível, mas o custo de envio será por conta do cliente.</p>
      <p class="mt-1">O uso de qualquer equipamento ou produto comercializado fora dos limites de tensão, temperatura ou a tentativa de reparação ou modificação irá anular a garantia.</p>
      <h4 class="my-2 font-medium text-gray-900">4- Reparos e restauração</h4>
      <p class="mt-1">A menos que a VW Soluções tenha determinado que o reparo é possível, a VW Soluções terá prazer em fornecer serviços de manutenção e reparo para equipamentos e produtos resultantes de uso indevido, altas tensões e tentativas de modificações incorretas. No entanto, durante este período, qualquer uso indevido por parte do cliente anulará a garantia.</p>
      <h4 class="my-2 font-medium text-gray-900">5- Retirada de equipamentos</h4>
      <p class="mt-1">Todos os equipamentos em conserto deverão ser retirados imediatamente após a comunicação por e-mail oficial da VW Soluções (vwsolucoes@gmail.com) após a conclusão dos procedimentos de manutenção e reparos.</p>
      <p class="mt-1">O proprietário de equipamentos eletrônicos, máquinas e motores entregues a serviços de assistência técnica para reparo fica obrigado a retirar o bem no prazo máximo de 180 (cento e oitenta) dias, contados da data em que foi informado sobre a efetiva realização do serviço de reparo ou sobre a eventual impossibilidade de realização do serviço.</p>
      <p class="mt-1">Ultrapassados 90 (noventa) dias da informação sobre a efetiva realização do serviço de reparo ou sobre a eventual impossibilidade de realização do serviço, a VW Soluções imediatamente notificará por e-mail ou telefone o proprietário, para que promova a retirada do bem do estabelecimento.</p>
      <p class="mt-1">Ultrapassados o período de 180 (cento e oitenta) dias, será definido pela VW Soluções, a cobrança de diárias referente ao armazenamento e higienização do equipamento no valor de R$5,99 por dia, a retirada do equipamento será liberada somente após o pagamento do total das diárias acumuladas.</p>
      <p class="mt-1">Com base no Art. 1.261 do CC (Código Civil) da Usucapião. Se a posse da coisa móvel se prolongar por cinco anos (1.825 dias), produzirá usucapião, independente de título ou boa-fé.</p>
      <p class="mt-1">Os procedimentos citados acima são válidos para todos os produtos e equipamentos, independente dos status de garantia do mesmo.</p>
      <p class="mt-1">É de fundamental importância a informação, por parte do cliente, de todos os meios de comunicação que venham facilitar o contato de nossos técnicos: Endereços completos com CEP, telefones fixos e móveis, e-mail, permitindo que a empresa estabeleça os contatos necessários dentro dos prazos legais.</p>
      <p class="mt-1">O cliente será informado pela VW Soluções sobre a finalização do reparo do equipamento por meio dos canais de comunicação (E-mail).</p>
      <p class="mt-1">Em caso de dúvidas a respeito de retirada de equipamentos, nossos canais de atendimento estão à disposição para maiores informações, inclusive através do e-mail: vwsolucoes@gmail.com</p>
      <h4 class="my-2 font-medium text-gray-900">6- Propriedade intelectual</h4>
      <p class="mt-1">A VW Soluções é proprietária de todos os direitos de propriedade intelectual sobre seu site, todo seu conteúdo, serviços, produtos, marcas, nomes comerciais, logotipos, desenhos, imagens, frases publicitárias, direitos autorais, domínios, programas de computação, códigos, desenvolvimentos, software, bancos de dados, informações, tecnologia, patentes e modelos de utilidade, desenhos e modelos industriais, segredos comerciais, entre outros (doravante designada a “Propriedade Intelectual”), que estão protegidos por leis nacionais e internacionais.</p>
      <p class="mt-1">Embora a VW Soluções conceda permissão para usar seus produtos e serviços conforme previsto nos Termos e Condições, isso não implica uma autorização para usar sua Propriedade Intelectual, exceto consentimento prévio e expresso da VW Soluções e/ou suas sociedades relacionadas. Em qualquer caso, os usuários vendedores que utilizem esses produtos e serviços não poderão utilizar a Propriedade Intelectual da VW Soluções de uma forma que cause confusão no público, e deverão realizar sua atividade comercial sob uma marca ou nome comercial próprio e distintivo, que não seja confundível com a marca VW Soluções.</p>
      <p class="mt-1">É proibido usar nossos produtos ou serviços para fins ilegais, realizar qualquer tipo de engenharia reversa ou obras derivadas, usar ferramentas de busca ou extração de dados e conteúdo de nossa plataforma para reutilização e/ou criar bancos de dados próprios que incluam, no todo ou em parte, nosso conteúdo sem nossa expressa autorização. É também proibido o uso indevido, sem autorização e/ou contrário às normas vigentes e/ou que gere confusão ou implique uso difamatório e/ou que cause prejuízo, danos ou perdas a VW Soluções e/ou suas sociedades relacionadas. A utilização dos produtos e serviços da VW Soluções também não implica a autorização para usar propriedade intelectual de terceiros que possa estar envolvida, cujo uso ficará sob exclusiva responsabilidade do usuário.</p>
      <p class="mt-1">Caso uma Pessoa Usuária ou qualquer anúncio infrinja a Propriedade Intelectual da VW Soluções, a VW Soluções poderá remover esse anúncio totalmente ou parcialmente, sancionar o usuário conforme previsto nestes Termos e Condições e exercer as ações extrajudiciais e/ou judiciais correspondentes.</p>
      <h4 class="my-2 font-medium text-gray-900">7- Jurisdição e lei aplicável</h4>
      <p class="mt-1">Estes Termos e Condições são regidos pela lei local. Qualquer controvérsia decorrente de sua aplicação, interpretação, execução ou validade será resolvida pelos tribunais nacionais ordinários competentes, localizados na capital, salvo disposição específica de normas de ordem pública, como legislação relativa ao consumidor.</p>
    </Header>
  </div>
</template>

<script>
export default { components: { Header: () => import('@/components/layout/header') }}
</script>